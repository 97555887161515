import React from 'react'
import Header from './Header'

export const Wrapper = ({ children }) => {
  return (
    <div className="page-wrapper">
      <Header />
      {children}
    </div>
  )
}
