import React, { useLayoutEffect } from "react";
import ImagePerGallery from "./ImagePerGallery";
import { images } from '../data/imageGallery';


export default function Gallery() {
  const $ = window.$;
  

  useLayoutEffect(() => {
    
    if ($(".sortable-gallery .gallery-filters").length) {
      var $container = $(".gallery-container");
      $container.isotope({
        filter: "*",
        animationOptions: {
          duration: 750,
          easing: "linear",
          queue: false,
        },
      });
  
      $(".gallery-filters li a").on("click", function () {
        $(".gallery-filters li .current").removeClass("current");
        $(this).addClass("current");
        var selector = $(this).attr("data-filter");
        $container.isotope({
          filter: selector,
          animationOptions: {
            duration: 750,
            easing: "linear",
            queue: false,
          },
        });
        return false;
      });
    }

    if ($(".masonry-gallery").length) {
      var $grid = $(".masonry-gallery").masonry({
        itemSelector: ".grid",
        columnWidth: ".grid",
        percentPosition: true,
      });
  
      $grid.imagesLoaded().progress(function () {
        $grid.masonry("layout");
      });
    }


  },[$]);

  return (
    <section className="gallery-section section-padding" id="gallery">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="section-title">
              <div className="vertical-line">
                <span>
                  <i className="fi flaticon-two" />
                </span>
              </div>
              <h2>Foto Gallerie</h2>
            </div>
          </div>
        </div>{" "}
        {/* end section-title */}
        <div className="row">
          <div className="col col-xs-12 sortable-gallery">
            <div className="gallery-filters">
              <ul>
                <li>
                  <a data-filter="*" href="#" className="current">
                    Alle
                  </a>
                </li>
                <li>
                  <a data-filter=".jga" href="#">
                    JGA Mädels
                  </a>
                </li>
                <li>
                  <a data-filter=".jga-groom" href="#">
                    JGA Männer
                  </a>
                </li>
                <li>
                  <a data-filter=".shooting" href="#">
                    Fotoshooting
                  </a>
                </li>
                <li>
                  <a data-filter=".wedding" href="#">
                    Hochzeit
                  </a>
                </li>
                <li>
                  <a data-filter=".ceremony" href="#">
                    Zermonie
                  </a>
                </li>
                <li>
                  <a data-filter=".party" href="#">
                    Party
                  </a>
                </li>
              </ul>
            </div>
            <div className="gallery-container gallery-fancybox masonry-gallery">
              {images.map((image, id) => (
                <ImagePerGallery key={id} {...image} />
              ))}
            </div>
          </div>
        </div>{" "}
        {/* end row */}
      </div>{" "}
      {/* end container */}
    </section>
  );
}
