import React, { useEffect } from "react";
import  Logo from '../Geweih.svg'


export default function Hero() {
  const $ = window.$;
  useEffect(() => {
    var saveTheDateCircle = $(".save-the-date");
    saveTheDateCircle.addClass("popup-save-the-date");
  }, []);

  return (
    <section id="hero" className="hero">
      <div className="static-hero" />
      <div className="wedding-announcement">
        <div className="couple-name-merried-text">
          <h2 className="wow slideInUp" data-wow-duration="1s">
            Simone &amp; Sebastian
            {/* Simone <img style={{width:'5rem'}} src={Logo} /> Sebastian */}
          </h2>
          <div className="married-text wow fadeIn" data-wow-delay="1s">
            <h4>
              {/* <span className=" wow fadeInUp" data-wow-delay="1.05s">
                W
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.10s">
                i
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.15s">
                r
              </span>
              <span>&nbsp;</span>
              <span className=" wow fadeInUp" data-wow-delay="1.30s">
                w
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.35s">
                e
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.40s">
                r
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.45s">
                d
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.50s">
                e
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.55s">
                n
              </span>
              <span>&nbsp;</span>
              <span className=" wow fadeInUp" data-wow-delay="1.65s">
                h
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.70s">
                e
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.75s">
                i
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.80s">
                r
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.85s">
                a
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.90s">
                t
              </span>
              <span className=" wow fadeInUp" data-wow-delay="1.95s">
                e
              </span>
              <span className=" wow fadeInUp" data-wow-delay="2.00s">
                n
              </span> */}
              <span className="wow fadeInUp">Liebe ist kein Ziel, sie ist eine Reise</span>
            </h4>
          </div>
          {/* <i class="fa fa-heart"></i> */}
        </div>
        <div className="save-the-date">
          <h4>Save the Date</h4>
          <span className="date">24 Oktober 2020</span>
        </div>
      </div>
    </section>
  );
}
