import React from "react";

export default function People(props) {
  // console.log(props)
  return (
    <div className="grid">
      <div className="img-holder">
        {/* <a href={`images/${props.photo}`} className="popup-image"> */}
          <img
            src={`images/${props.photo}`}
            alt=""
            className="img img-responsive"
          />
        {/* </a> */}
      </div>
      <div className="details">
        <h3>{props.name}</h3>
        <span>{props.text}</span>
        <p style={{textAlign:'left'}} dangerouslySetInnerHTML={{ __html: props.long }}></p>
      </div>
    </div>
  );
}
