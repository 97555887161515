import React from "react";

export default function Info() {
  return (
    <section
      className="cta section-padding parallax"
      data-bg-image="images/cta-bg.jpg"
      data-speed="7"
      style={{padding:'20px', color:'white'}}
    >
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <>
              <span>
               " Wir feiern eine TRACHTENHOCHZEIT. <br/><br/>
                Wir würden uns daher sehr
                freuen, wenn wir euch ebenfalls in Tracht begrüßen dürfen. <br/><br/>
                Bitte gebt uns bis allerspätestens 1. SEPTEMBER per Email,
                telefonisch oder auf unserer Webseite Bescheid, ob ihr kommen
                könnt. "<br/>
                <br/>
                Veranstaltungsort: <a style={{color: 'white', textDecoration: 'underline'}} href="https://goo.gl/maps/pwMyJ3Nszjs1yGo46">Bitte hier Klicken</a><br/><br/>
                Newsletter: <a style={{color: 'white', textDecoration: 'underline'}} href="newsletter.pdf" target="blank">Herunterladen als PDF</a>
              </span>
              
            </>
          </div>
        </div>
      </div>
    </section>
  );
}
