/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect } from "react";

export default function RVSP() {
  const $ = window.$;
  const emailjs = window.emailjs;

  function handleSubmit(e) {
    e.preventDefault();

    var service_id = "default_service";
    var template_id = "template_fuLsX9lb";

    emailjs
      .sendForm(service_id, template_id, e.target, "user_zzoxlCdpYveS4JVwgWcbX")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  useEffect(() => {
    // if ($("#rsvp-form").length) {
    //   $("#rsvp-form").validate({
    //     rules: {
    //       name: {
    //         required: true,
    //         minlength: 2,
    //       },
    //       email: "required",
    //       guestadult: {
    //         required: true,
    //       },
    //       guestkids: {
    //         required: true,
    //       },
    //       menu: {
    //         required: true,
    //       },
    //     },
    //     messages: {
    //       name: "Bitte gib deinen Namen ein",
    //       email: "Wir benötigen auch deine Email Addresse",
    //       guestadult: "Anzahl Erwachsene Personen",
    //       guestkids: "Anzahl Kinder",
    //       menu: "Bitte wähle das Menü aus",
    //     },
    //     submitHandler: function (form) {
    //       $("#loader").css("display", "inline-block");
    //       $.ajax({
    //         type: "POST",
    //         url: "mail.php",
    //         data: $(form).serialize(),
    //         success: function () {
    //           $("#loader").hide();
    //           $("#success").slideDown("slow");
    //           setTimeout(function () {
    //             $("#success").slideUp("slow");
    //           }, 3000);
    //           form.reset();
    //         },
    //         error: function () {
    //           $("#loader").hide();
    //           $("#error").slideDown("slow");
    //           setTimeout(function () {
    //             $("#error").slideUp("slow");
    //           }, 3000);
    //         },
    //       });
    //       return false; // required to block normal submit since you used ajax
    //     },
    //   });
    // }
  }, []);

  return (
    <section
      className="rsvp-section section-padding parallax"
      data-bg-image="images/rsvp-bg.jpg"
      data-speed={7}
      id="rsvp"
    >
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="section-title-white">
              <div className="vertical-line">
                <span>
                  <i className="fi flaticon-two" />
                </span>
              </div>
              <h2>Anmeldeformular für  Gäste</h2>
            </div>
          </div>
        </div>
        <div className="row content">
          <div className="col col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">
            <form
              id="rsvp-form"
              className="form validate-rsvp-form row"
              // method="post"
              onSubmit={handleSubmit}
            >
              <p>Bitte gebt uns bis allerspätestens 1. September Bescheid.</p>
              <div className="col col-sm-6">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Dein Name*"
                />
              </div>
              <div className="col col-sm-6">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Deine E-mail Addresse*"
                />
              </div>
              <p>Personen</p>
              <div className="col col-sm-6">
                <select className="form-control" name="guestadult">
                  <option  defaultChecked>
                    Anzahl Erwachsene*
                  </option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>

              <div className="col col-sm-6">
                <select className="form-control" name="guestkids">
                  <option  defaultChecked>
                    Anzahl der Kinder*
                  </option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>

              <p>Menü für Erwachsene & Allergien<br></br><i>*Kinder bekommen ein gesondertes Menü*</i></p> <br></br>
              

              
              <div className="col col-sm-6">
                <select className="form-control" name="vegatarien">
                  <option defaultChecked >
                  Vegetarisch gewünscht? ( Anzahl )*
                  </option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>

              <div className="col col-sm-6">
                <div
                  className="form-control"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    background: "#e6eceb",
                  }}
                >
                  <label
                    htmlFor="laktose"
                    className="checkbox"
                    style={{ marginTop: "5px", fontWeight: "normal" }}
                  >
                    Laktose
                  </label>
                  <input
                    style={{ lineHeight: "30px", margin: "20px 0" }}
                    type="checkbox"
                    id="laktose"
                    className="checkbox"
                    name="laktose"
                  />
                  <label
                    htmlFor="gluten"
                    className="checkbox form-check-input"
                    style={{ marginTop: "5px", fontWeight: "normal" }}
                  >
                    Gluten
                  </label>
                  <input
                    style={{ lineHeight: "30px", margin: "20px 0" }}
                    type="checkbox"
                    id="gluten"
                    className="checkbox"
                    name="gluten"
                  />
                  <label
                    htmlFor="nuesse"
                    className="checkbox form-check-input"
                    style={{ marginTop: "5px", fontWeight: "normal" }}
                  >
                    Nüsse
                  </label>
                  <input
                    style={{ lineHeight: "30px", margin: "20px 0" }}
                    type="checkbox"
                    id="nuesse"
                    name="nuesse"
                    className="checkbox form-check-input"
                  />
                </div>
              </div>

              <div className="col col-sm-12">
                <textarea
                  className="form-control"
                  name="notes"
                  placeholder="Kommentar*"
                  defaultValue={""}
                />
              </div>

              <div className="col col-sm-12 submit-btn">
                <button type="submit" className="submit">
                  Anmeldung absenden
                </button>
                <span id="loader">
                  <i className="fa fa-refresh fa-spin fa-3x fa-fw" />
                </span>
              </div>
              <div className="col col-md-12 success-error-message">
                <div id="success">Wir freuen Uns auf Dich 😍</div>
                <div id="error">
                  Es ist ein Fehler aufgetreten. Bitte versuche es später
                  nochmals.
                </div>
              </div>
            </form>
          </div>
        </div>
        <section style={{color:"white", textAlign: 'center', margin:'50px auto'}}>
          <span>Simone (noch) Kohlbacher</span><br/><br/>
          <span> Tel +43 677 61 44 71 80</span><br/>
          <span> simone.kohlbacher@outlook.com  </span><br/>
          <span> Mariahilfstrasse 44, 6900 Bregenz  </span> 
        </section>
      </div>
    </section>
  );
}
