import React, {useEffect} from "react";
import People from "./People";

export default function ImportantPeople() {
  const Persons = [
    [
      {
        photo: "personen/Miri/Miri_Simone_Geburtstag_Sebi.jpg",
        name: "Miriam 'Die Große'",
        text: "Trauzeugin",
        type: "bridemain",
        long: `</p><strong>Miri</strong> ist eine der ältesten und besten Freundinnen, Nachbarin und nun auch TRAUZEUGIN von Simone.</p>
        SIE wäre nicht einmal in Peru durchschnittlich groß! 😉 Dennoch ist sie für uns die Größte.
        
        </p><strong>Warum Miri?</strong>
        </p>
        Sie gehört zu den <strong>gutmütigsten und positivsten Menschen </strong>, die wir kennen. Sie ist immer fröhlich und gut gelaunt. 
        Miri gehört zu den Menschen, die sich jeder wünscht um sich zu haben. Ihr Lachen ist ansteckend, Ihre Ideen sind mitreißend!
        Simone und Miri kennen sich seit sie denken können und auch wenn sie sich für viele Jahre aus den Augen verloren haben, 
        ist Simone unglaublich dankbar, dass Miri wieder Teil ihres Lebens geworden ist. 
        </p>Wir freuen uns, dass sie JA gesagt hat! “Ja”, Simone´s Entscheidungsschwächen, Stimmungsschwankungen und Launen zu ertragen. 
        “Ja”, uns in einer der wichtigsten Phasen unseres Lebens zu begleiten.
        </p><strong>Danke, liebste Miri, dass du an unserer Seite bist! Danke, dass du so bist wie du bist! 💓 </p>Einfach die Größte!</strong>`,
      },
      {
        photo: "personen/Nils/Nils Sebi Kochen St. Patricksday.jpg",
        name: "Nils",
        text: "Trauzeuge & Freund für alle Fälle",
        type: "grooman",
        long: `</p>Sebastian und sein Trauzeuge <strong>Nils</strong>. </p>Ein Unterschied wie Tag und Nacht! ...oder doch nicht?! </p>
        Auf den ersten Blick wirken die beiden zusammen fast schon komisch. 
        Wenn man sie aber gemeinsam erlebt, merkt man schnell, dass die Unterschiede so groß nicht sind. </p>
        Beides sind Genussmenschen, hören sich ungeheuer gerne reden, auch schon mal aneinander vorbei und haben einen ähnlichen Humor. 
        Das sticht deshalb so markant heraus, weil oft nur die beiden über ihre Witze lachen können. </p>
        Nils ist ein handwerklicher Alleskönner, immer da, wenn man ihn braucht und stets gut gelaunt. 
        ...und das ist nicht selbstverständlich. </p><strong>Er ist DER Freund für alle Fälle! 👬</strong>`
      },
      {
        photo: "personen/Herwig/Sebi, Herwig Tennis.jpg",
        name: "Herwig",
        text: "Trauzeuge & Brother from another mother",
        type: "grooman",
        long: `</p><strong>Herwig</strong> ist Sebastian´s Bruder.</p> Nicht wirklich, eigentlich ist er der Schwager 🙌.</p>
        Aber es war ziemlich schnell klar, dass Herwig "Sebi´s brother from another mother 😁" (übers.: Bruder einer anderen Mutter) ist. </p>
        Als Sebi´s Schwester Herwig damals der Familie vorstellte, dauerte es nicht lange und er meinte zu seiner Schwester: "Jacky, versau es bloß nicht!" 
        Zu groß war die Angst, Herwig wieder zu verlieren.</p>
        Herwig ist für jeden Spaß zu haben, ob Nachtspaziergänge bei Minusgraden mit anschließender Männergrippe, einen Tischtanz beim Apres Ski, Bierdusche im Lokal oder Skifahren bei Minus 20 ° C, Herwig ist mit Begeisterung dabei!
        </p>Die beiden verbindet, neben Sebi´s Schwester, v.a. der Sport.</p>
        <strong>Ob beim Tauchen, Windsurfen, Krafttraining oder seit neuestem auch Tennis.... sie messen sich – wie es sich für richtige Brüder 😄 gehört - wo es nur geht.</strong>`,
      },
    ],
    [
      {
        photo: "personen/Joschi_Maria/Foto.jpg",
        name: "Josef  & Maria",
        text: "Eltern von Sebastian",
        type: "family",
        long: "",
      },
      
      {
        photo: "personen/Siggi/IMG-20200523-WA0004.jpg",
        name: "Sigi",
        text: "Vater von Simone",
        type: "family",
        long: "",
      },
      {
        photo: "personen/Greti/IMG-20190630-WA0002.jpg",
        name: "Greti",
        text: "Mutter von Simone",
        type: "family",
        long: "",
      },
      {
        photo: "personen/Jacky/IMG-20191219-WA0011.jpg",
        name: "Jacqueline & Family",
        text: "Schwester des Bräutigams",
        type: "family",
        long: "",
      },
      {
        photo: "personen/Daniel/IMG-20200531-WA0006.jpg",
        name: "Daniel & Family",
        text: "Bruder der Braut",
        type: "family",
        long: "",
      },
      {
        photo: "personen/Silke/IMG-20200601-WA0001.jpg",
        name: "Silke & Vanessa",
        text: "Schwester und Nichte der Braut",
        type: "family",
        long: "",
      },
      
    ],
  ];

  const $ = window.$;

  useEffect(() => {
   
      if ($(".masonry-gallery").length) {
        var $grid = $(".masonry-gallery").masonry({
          itemSelector: ".grid",
          columnWidth: ".grid",
          percentPosition: true,
        });
    
        $grid.imagesLoaded().progress(function () {
          $grid.masonry("layout");
        });
      }
    
  }, [$])

  return (
    <section className="inportant-people-section section-padding" id="people">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="section-title">
              <div className="vertical-line">
                <span>
                  <i className="fi flaticon-two" />
                </span>
              </div>
              <h2>Trauzeugin/Trauzeugen &amp; Familie</h2>
            </div>
          </div>
        </div>{" "}
        {/* end section-title */}
        <div className="row">
          <div className="col col-xs-12">
            <div className="inportant-people-content">
              <div className="tablist">
                <ul className="nav">
                  <li className="active">
                    <a href="#honor" data-toggle="tab">
                      Trauzeugin & Trauzeugen
                    </a>
                  </li>
                  <li>
                    <a href="#family" data-toggle="tab">
                      Familie
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                {/* Trauzeugin platzhalter */}
                <div
                  className="tab-pane fade  grid-wrapper active in"
                  id="honor"
                >
                  {Persons[0].map((person, i) => (
                    <People key={i} {...person} />
                  ))}
                </div>
                {/* Trauzeugin platzhalter */}

                {/* Familie */}
                <div className="tab-pane fade  grid-wrapper" id="family">
                  {Persons[1].map((person, i) => (
                    <People key={i} {...person} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>{" "}
    </section>
  );
}
