import React from "react";

export default function WeddingCoupleSection() {
  return (
    <section className="wedding-couple-section section-padding" id="couple">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="gb bride">
              <div className="details">
                <div className="details-inner">
                  <h3>Die Braut</h3>
                  <p style={{ textAlign: "left" }}>
                    Es gibt nicht viele Frauen, die alle zwei Stunden Hunger bekommen.
                    Es gibt vermutlich auch nicht viele Frauen, die shoppen
                    (insbesondere Schuhe) als Qual empfinden. Und auch, wenn sie
                    behauptet "normal groß" zu sein, ist ihre Größe höchstens in
                    Peru durchschnittlich. Sie ist manchmal unverblümt ehrlich,
                    ja manche behaupten sogar, "gemein"?! Dabei meint sie es
                    doch eigentlich nur gut. <span>🙁</span>  Sie schläft grundsätzlich vor
                    dem Fernseher ein und fühlt sich ohne das tägliche
                    Kuschelprogramm mit Sebi nicht wohl in ihrer Haut. Essen
                    gibt ihrem Leben einen Sinn. Sollte der Tag einmal nicht
                    mehr zu retten sein, helfen Margaritas und Sebi´s leckere
                    Nudelgerichte.
                  </p>
                  <span className="signature">Simone</span>
                </div>
              </div>
              <div className="img-holder wow fadeInRightSlow">
                <img src="images/personen/Simone/simone2.jpg" alt="Braut" />
              </div>
            </div>
            <div className="gb groom">
              <div className="img-holder wow fadeInLeftSlow">
                <img src="images/personen/Sebi/IMG-20200625-WA0015.jpg" alt="Bräutigam" />
              </div>
              <div className="details">
                <div className="details-inner">
                  <h3>Der Bräutigam</h3>
                  <p>
                    Entsprechend seinem Sternzeichen “Zwillinge” hat Sebastian 2
                    Seiten:
                    <br></br><br></br> Einerseits ist er ehrgeizig, motiviert, zielstrebig
                    und fleißig. Er ist eigentlich immer am Arbeiten.
                    Andererseits könnte man meinen, er habe den Begriff “Dolce
                    Vita” mitgeprägt. Er weiß das “süße Leben” durchaus zu
                    genießen. Ob es ums Kochen, Essen, Trinken, Feiern oder
                    Reisen geht. Genuss wird in seinem Leben ganz “groß”
                    geschrieben und es kommt nicht selten vor, dass dieser auch
                    ab & zu ausartet. Neben Simone gehört sein Herz dem Kochen,
                    seiner Arbeit und dem Wassersport.
                  </p>
                  <span className="signature">Sebastian</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
