import React, { useState } from "react";

export default function CountDown() {
  const [clock, setClock] = useState("");

  const weddingDate = new Date("2020/10/24 14:00:00").getTime();
  //  Set Coutndown Interval
  const x = setInterval(() => {
    let now = new Date().getTime();
    let distance = weddingDate - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    const ClockTime = (
      <>
        <div className="box">
          <div>{days}</div> <span>Tage</span>{" "}
        </div>
        <div className="box">
          <div>{hours}</div> <span>Stunden</span>{" "}
        </div>
        <div className="box">
          <div>{minutes}</div> <span>Minuten</span>{" "}
        </div>
        <div className="box">
          <div>{seconds}</div> <span>Sekunden</span>{" "}
        </div>
      </>
    );
    setClock(ClockTime);
    if (distance < 0) {
      clearInterval(x);
      setClock("Verganen");
    }
  }, 1000);

  return (
    <section
      className="count-down-section section-padding parallax"
      data-bg-image="images/countdown-bg.jpg"
      data-speed="7"
    >
      <div className="container">
        <div className="row">
          <div className="col col-md-4">
            <h2>
              <span>Wir warten auf "Unser Abenteuer".....</span> Die Hochzeit
            </h2>
          </div>
          <div className="col col-md-7 col-md-offset-1">
            <div className="count-down-clock">
              <div id="clock">{clock}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
