import React, { useEffect } from "react";

export default function StorySection() {
  const $ = window.$;

  /*------------------------------------------
  = STORY SLIDER
  -------------------------------------------*/
  useEffect(() => {
    if ($(".story-slider").length > 0) {
      $(".story-slider").owlCarousel({
        items: 1,
        dots: true,
        autoplay: true,
        autoplayTimeout: 2000,
        smartSpeed: 4000,
        loop: true,
        autoHeight: false,
      });
    }
  }, []);

  return (
    <section className="story-section section-padding" id="story">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="section-title">
              <div className="vertical-line">
                <span>
                  <i className="fi flaticon-two" />
                </span>
              </div>
              <h2>Unsere Geschichte</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="story-timeline">
              <div className="row">
                <div className="col col-md-6">
                  <div className="story-text right-align-text">
                    <h3>Kennnenlernen</h3>
                    <span className="date">25 Oktober 2005</span>
                    <p style={{ textAlign: "left" }}>
                      Es war ein kühler Herbstabend.
                      <br></br><br></br>
                      Nein, Simone hatte keine Lust mehr, das Bett zu verlassen,
                      sich zu richten und in die Kälte rauszugehen. Wie immer
                      ließ sie sich von ihren Kollegen trotzdem überreden und
                      fand sich eine Stunde später auf dem Kiesparkplatz in der
                      Stadt wieder.
                      <br></br><br></br>
                      Es war dunkel und kalt. Einer der beiden Kollegen,
                      nennen wir ihn Alex, erschien erst gleich gar nicht. Er
                      hatte Wichtigeres zu tun und sendete daher “eine
                      Vertretung” - seinen Klassenkollegen und Simone´s neuen
                      Schwarm 💖 SEBASTIAN.
                    </p>
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="img-holder right-align-text story-slider">
                    <img
                      src="images/personen/Simone & Sebi/2007_0805SEBI0197.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    <img
                      src="images/personen/Simone & Sebi/DSC00422.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    <img
                      src="images/personen/Simone & Sebi/DSCF0930.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-6">
                  <div className="img-holder right-align-text ">
                    <img
                      src="images/personen/Simone & Sebi/IMG-20191102-WA0002.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                  </div>
                </div>
                <div className="col col-md-6 text-holder">
                  <span className="heart">
                    <i className="fa fa-heart" />
                  </span>
                  <div className="story-text">
                    <h3></h3>
                    {/* <span className="date">Oktober 26 2005</span> */}
                    <p style={{ textAlign: "left" }}>
                      Zu dritt machten wir eine Kneipentour durch Bregenz, wobei
                      uns die 3. Person irgendwo abhandengekommen ist! Wir
                      verstehen nicht warum?!
                      <br></br><br></br>
                      Wir waren die ganze Nacht unterwegs und haben geredet,
                      geredet, geredet. So ging es die folgenden 3 Tage weiter.
                      Nach knapp 72 Stunden, mit nur etwa 6 Stunden Schlaf,
                      hatten wir uns unsere Lebensgeschichten endlich erzählt.
                      <br></br><br></br>
                      Wir können heute nur noch schwer sagen, ab wann wir
                      offiziell ein Paar waren. Wir haben uns kennengelernt und
                      gehören seither zusammen 💑.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-6 text-holder right-heart">
                  <span className="heart">
                    <i className="fa fa-heart" />
                  </span>
                  <div className="story-text right-align-text">
                    <h3>Schweiz</h3>
                    <span className="date">2009 - 2015</span>
                    <p style={{ textAlign: "left" }}>
                      In Beziehungen gibt es nicht nur gute, sondern eben auch
                      nicht so gute Zeiten.
                      <br></br><br></br>
                      Die Schweiz gehört beziehungsmäßig sicher zu letzterem. Es
                      war eine Zeit voll Berg- und Talfahrten. Erste gemeinsame
                      Wohnung, Unabhängigkeit, Abenteuer, Herausforderungen!
                      Aber es gab auch Einsamkeit, Zweifel, Heimweh,
                      Überforderung und Trennung.
                      <br></br><br></br>
                      Nein, wir erinnern uns nicht gerne daran, aber wie alles
                      im Leben hat alles Negative auch etwas Positives.
                      <br></br><br></br>
                      Diese schwierige Zeit hat uns als Paar sicher
                      stärker gemacht.
                    </p>
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="img-holder right-align-text">
                    
                    <img
                      src="images/personen/Simone & Sebi/DSC00303.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col col-md-6">
                  <div className="img-holder right-align-text story-slider">
                    <img
                      src="images/personen/Simone & Sebi/Hoi An (220).jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    <img
                      src="images/personen/Simone & Sebi/DSC05000.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    <img
                      src="images/personen/Simone & Sebi/DSC05454.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    <img
                      src="images/personen/Simone & Sebi/IMG_1568.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                  </div>
                </div>
                <div className="col col-md-6 text-holder">
                  <span className="heart">
                    <i className="fa fa-heart" />
                  </span>
                  <div className="story-text">
                    <h3>Unsere Weltreise</h3>
                    <span className="date">Februar - August 2015</span>
                    <p style={{ textAlign: "left" }}>
                      Und diese neue Stärke haben wir dann auch gleich auf die
                      Probe gestellt.
                      <br></br><br></br>
                      10 Länder, 6 Monate, 24 Stunden am Tag. Wir genossen
                      "unsere Auszeit". Wir nahmen sehr viel Rücksicht
                      aufeinander, stritten kaum und waren uns bewusst, dass wir
                      für diese Zeit die einzige Bezugsperson für den jeweils
                      anderen sein würden. Es war eine Erfahrung, die uns nicht
                      nur persönlich geprägt, sondern auch als Paar enger
                      zusammengeschweißt hat.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col col-md-6 text-holder right-heart">
                  <span className="heart">
                    <i className="fa fa-heart" />
                  </span>
                  <div className="story-text right-align-text">
                    <h3>Verlobung</h3>
                    <span className="date">06 Juli 2019</span>
                    <p style={{ textAlign: "left" }}>
                      Und weil wir schon so eng zusammengeschweißt waren, dachte
                      sich Sebastian wohl:"Diese Frau will ich unbedingt
                      heiraten und für immer an meiner Seite haben!"
                      <br></br><br></br>
                      Am 05. Juli 2019 fuhren Sebastian, Simone und Simone´s
                      Eltern nach Wien. Simone hatte Karten für die konzertante
                      Open Air-Aufführung von “Elisabeth” ergattert.
                      <br></br><br></br>
                      Während der knapp 7-stündigen Zugfahrt, traute sich
                      Sebastian nicht seinen Platz zu verlassen. Zu groß war die
                      Gefahr, dass Simone in seinem Rucksack nach einem
                      Ladekabel, Wasser etc. suchen und den Ring entdecken
                      könnte. Ja, er hätte den Ring im Koffer verstauen können,
                      aber auch das wäre nicht ohne Risiko gewesen. Also dann
                      doch lieber 7 Stunden ohne WC und Beine-vertreten.
                      <br></br><br></br>
                      Bei der Veranstaltung am Abend war Sebastian der einzige
                      Mann mit Blazer.
                      <br></br><br></br>
                      Warum musste ausgerechnet dieser Tag der bis dahin
                      heißeste Tag des Jahres sein? Sebastian verging fast vor
                      Hitze. Aber ablegen war keine Option. Die riesige
                      Ringschatulle hätte herausfallen können. ...dann doch
                      lieber weiterschwitzen. Simone fand das nicht im
                      Geringsten seltsam. Was hatte sie doch für einen tollen,
                      eleganten Mann an ihrer Seite! 🕴
                      <br></br><br></br>
                      Sebastian hatte alles perfekt geplant: Er wollte den
                      Antrag nach der Aufführung ganz romantisch im
                      Schlossgarten machen. Der Türsteher war bestochen, Simone
                      dank der tollen Aufführung auf Wolke 7. Eigentlich konnte
                      nichts mehr schief gehen. Eigentlich.... Simone hatte
                      schlimme Bauchschmerzen und weigerte sich einen
                      Spaziergang zu machen. Also fuhren die von Bauchschmerzen
                      geplagte, nichts ahnende Simone und der angesäuerte,
                      verzweifelte Sebastian unverrichteter Dinge wieder ins
                      Hotel zurück.
                      <br></br><br></br>
                      Was sollte er nun tun? Improvisieren? Und wie wird er die
                      Schwiegereltern auf die Schnelle wieder los? Simone konnte
                      die plötzlich so schlechte Laune nicht nachvollziehen,
                      immerhin war sie diejenige, die Schmerzen hatte!
                      <br></br><br></br>
                      Von den 50 Euro Bestechungsgeld, die in diesem Moment
                      flöten gingen, wusste sie nichts.
                    </p>
                  </div>
                </div>
                <div className="col col-md-6">
                  <div className="img-holder right-align-text story-slider">
                    
                    <img
                      src="images/personen/Verlobung_Trauzeugen/IMG_20190706_093307.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    <img
                      src="images/personen/Verlobung_Trauzeugen/Sebi, Simone Verlobung Wien.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    <img
                      src="images/personen/Verlobung_Trauzeugen/IMG_20190705_215423.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                  </div>
                </div>
              </div>

              {/* Weiter */}
              <div className="row">
                <div className="col col-md-6">
                  <div className="img-holder right-align-text story-slider">
                    
                    <img
                      src="images/personen/Verlobung_Trauzeugen/Palmenhaus IV.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                    
                    <img
                      src="images/personen/Verlobung_Trauzeugen/IMG_20190727_163039.jpg"
                      alt=""
                      className="img img-responsive"
                    />
                  </div>
                </div>
                <div className="col col-md-6 text-holder left-heart">
                  <span className="heart">
                    <i className="fa fa-heart" />
                  </span>
                  <div className="story-text right-align-text">
                    {/* <h3>Verlobung - 2/2</h3> */}
                    {/* <span className="date">Apr 14 2017</span> */}
                    <p style={{ textAlign: "left" }}>
                      Am nächsten Tag wollten sich die ansonsten nicht so faulen
                      (Schwieger)Eltern am Abend nicht mehr nach draußen
                      bewegen.
                      <br></br><br></br>
                      Der Spaziergang am Nachmittag bei gefühlten 40
                      Grad im Schatten war vielleicht doch etwas zu viel gewesen?
                      Sebastian erkannte seine 2. Chance und schlug vor, man
                      könne doch nochmal zum Schloss Schönbrunn fahren und ein
                      bisschen im Schlosspark spazieren gehen.
                      <br></br><br></br>
                      Eigentlich hatte der Park bereits geschlossen.
                      Sebastian wollte dennoch spazieren gehen. Es fing bereits
                      an zu dämmern. Der Park war bis auf wenige Spaziergänger
                      menschenleer. Die Beleuchtungen waren bereits
                      ausgeschalten und die Bewässerungsanlagen in Betrieb - und
                      bewässerten nicht nur die Grünflächen. 😉 Simone kam sich
                      vor wie eine Einbrecherin.
                      <br></br><br></br>
                      Vor dem Palmenhaus setzten sie sich auf eine
                      Parkbank. Fledermäuse flogen über das Glashaus hinweg.
                      Sebastian hatte feuchte Hände und Simone spürte, dass
                      etwas nicht stimmte. Spätestens, als er sie auf dem Weg
                      zur Bank fast stehen ließ. Als er die Fragen aller Fragen
                      gestellt hatte, reagierte Simone – nicht wie erhofft -
                      zunächst mit einer Gegenfrage: “Hast du dir das gut
                      überlegt?”, aber auch wenn er “nein” gesagt hätte, hätte
                      sie ihm den Ring sicher nicht mehr zurückgegeben.
                      Natürlich sagte sie anschließend überglücklich “JA”!
                      Sebastian konnte sein Glück kaum fassen. Nicht nur, weil
                      Simone “ja” gesagt hatte.
                      <br></br><br></br>
                      Endlich war er diesen immer schwerer gewordenen
                      Ring los. “Der Herr der Ringe” sieht er nun mit ganz
                      anderen Augen.
                      <br></br><br></br>
                      Über den Dächern von Wien stießen wir auf die Verlobung 💍
                      und den Beginn unseres neuen Lebensabschnitts an.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
