import React, { useEffect, useState } from "react";
const $ = window.$;
const WOW = window.WOW;

export default function Header() {
  // console.log(window.$);
  
  const [spinner, setSpinner] = useState(true);
  const [sidebar, setSidebar] = useState(false);
  
  useEffect(() => {
    

    setTimeout(() => {
      setSpinner(false);
    }, 1200);

    /*------------------------------------------
        = WOW ANIMATION SETTING
    -------------------------------------------*/
    var wow = new WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 1, // default
      mobile: false, // default
      live: false, // default
    });

    //active wow
    wow.init();
    // console.log(window);

    // Function for toggle a class for small menu
    function toggleClassForSmallNav() {
      let windowWidth = window.innerWidth;
      let mainNav = $("#navbar > ul");

      if (windowWidth <= 991) {
        mainNav.addClass("small-nav");
      } else {
        mainNav.removeClass("small-nav");
      }
    }
    toggleClassForSmallNav();

    // Function for small menu
    function smallNavFunctionality() {
      let windowWidth = window.innerWidth;
      let mainNav = $(".navigation-holder");
      let smallNav = $(".navigation-holder > .small-nav");
      let subMenu = smallNav.find(".sub-menu");
      let megamenu = smallNav.find(".mega-menu");
      let menuItemWidthSubMenu = smallNav.find(".menu-item-has-children > a");

      if (windowWidth <= 991) {
        subMenu.hide();
        megamenu.hide();
        menuItemWidthSubMenu.on("click", function (e) {
          var $this = $(this);
          $this.siblings().slideToggle();
          e.preventDefault();
          e.stopImmediatePropagation();
        });
      } else if (windowWidth > 991) {
        mainNav.find(".sub-menu").show();
        mainNav.find(".mega-menu").show();
      }
    }
    smallNavFunctionality();

    // Parallax background
    function bgParallax() {
      if ($(".parallax").length) {
        $(".parallax").each(function () {
          let height = $(this).position().top;
          let resize = height - $(window).scrollTop();
          let parallaxSpeed = $(this).data("speed");
          let doParallax = -(resize / parallaxSpeed);
          let positionValue = doParallax + "px";
          let img = $(this).data("bg-image");

          $(this).css({
            backgroundImage: "url(" + img + ")",
            backgroundPosition: "50%" + positionValue,
            backgroundSize: "cover",
          });

          if (window.innerWidth < 768) {
            $(this).css({
              backgroundPosition: "center center",
            });
          }
        });
      }
    }
    bgParallax();

    // set two coloumn height equial
    function setTwoColEqHeight($col1, $col2) {
      var firstCol = $col1,
        secondCol = $col2,
        firstColHeight = $col1.innerHeight(),
        secondColHeight = $col2.innerHeight();

      if (firstColHeight > secondColHeight) {
        secondCol.css({
          height: firstColHeight + 1 + "px",
        });
      } else {
        firstCol.css({
          height: secondColHeight + 1 + "px",
        });
      }
    }

    /*==========================================================================
        WHEN DOCUMENT LOADING
    ==========================================================================*/

    smallNavFunctionality();

    //set the couple section groom bride two col equal height
    if ($(".wedding-couple-section").length) {
      setTwoColEqHeight(
        $(".wedding-couple-section .gb .img-holder"),
        $(".wedding-couple-section .gb .details")
      );
    }

    /*==========================================================================
      WHEN WINDOW RESIZE
  ==========================================================================*/
    $(window).on("resize", function () {
      toggleClassForSmallNav();
      //smallNavFunctionality();

      clearTimeout($.data(this, "resizeTimer"));
      $.data(
        this,
        "resizeTimer",
        setTimeout(function () {
          smallNavFunctionality();
        }, 200)
      );
    });
  });

  function toggleSideBar(e) {
    setSidebar(!sidebar);
    // console.log(sidebar);
  }

  return !spinner ? (
    <header id="header" className="site-header header-style-2">
      <nav className="navigation navbar navbar-default">
        <div className="container">
          <div className="navbar-header">
            <button
              onClick={(e) => toggleSideBar(e)}
              type="button"
              className="open-btn"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <div className="couple-logo">
              <h1>
                <a href="/">
                  S <i className="fi flaticon-shape-1" /> S
                </a>
              </h1>
            </div>
          </div>
          <div
            id="navbar"
            className={
              sidebar
                ? "navbar-collapse collapse navbar-right navigation-holder slideInn"
                : "navbar-collapse collapse navbar-right navigation-holder"
            }
          >
            <button className="close-navbar">
              <i onClick={(e) => toggleSideBar(e)} className="fa fa-close" />
            </button>
            <ul className="nav navbar-nav">
              <li>
                <a href="#hero">Start</a>
              </li>
              <li>
                <a href="#couple">Brautpaar</a>
              </li>
              <li>
                <a href="#story">Unser Geschichte</a>
              </li>
              {/* <li>
                <a href="#events">Programm</a>
              </li> */}
              <li>
                <a href="#people">Wunderbare Menschen</a>
              </li>
              <li>
                <a href="#gallery">Gallerie</a>
              </li>
              <li>
                <a href="#rsvp">Anmeldung</a>
              </li>
              {/* <li>
                <a>Blog</a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  ) : (
    <div className="preloader">
      <div className="inner">
        <span className="icon">
          <i className="fi flaticon-two"></i>
        </span>
      </div>
    </div>
  );
}
