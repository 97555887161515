import React from "react";

export default function ImagePerGallery(props) {
  return (
    <div className={`grid ${props.type}`}>
      <a href={props.src} className="fancybox" data-fancybox-group="gall-1">
        <img src={props.src} alt="" className="img img-responsive" />
      </a>
    </div>
  );
}

// const Images = () => {
//   console.log();

//   return (
//     <div className="grid wedding">
//       <a
//         href="images/gallery/img-1.jpg"
//         className="fancybox"
//         data-fancybox-group="gall-1"
//       >
//         <img src={`test`} alt="" className="img img-responsive" />
//       </a>
//     </div>
//   );
// };
