export const images = [
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG_20200627_162210.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG_20200627_163134.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG_20200627_182314.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG-20200627-WA0008.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG-20200627-WA0013.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG-20200627-WA0038.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG-20200627-WA0039.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG-20200627-WA0043.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG-20200627-WA0051.jpg",
  },
  {
    title: "",
    type: "jga",
    src: "images/gallery/jga/IMG-20200627-WA0070.jpg",
  },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200713-WA0005.jpg",
  },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200713-WA0006.jpg",
  },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200714-WA0009.jpg",
  },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200714-WA0010.jpg",
  },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200714-WA0011.jpg",
  },
  // {
  //   title: "",
  //   type: "jga-groom",
  //   src: "images/gallery/jga-groom/IMG-20200714-WA0012.jpg",
  // },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200714-WA0021.jpg",
  },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200714-WA0032.jpg",
  },
  {
    title: "",
    type: "jga-groom",
    src: "images/gallery/jga-groom/IMG-20200714-WA0040.jpg",
  },
  {
    title: "",
    type: "shooting",
    src: "images/gallery/fotoshooting/wand.jpg",
  },
  {
    title: "",
    type: "shooting",
    src: "images/gallery/fotoshooting/herz.jpg",
  },
  {
    title: "",
    type: "shooting",
    src: "images/gallery/fotoshooting/nah.jpg",
  },
  {
    title: "",
    type: "shooting",
    src: "images/gallery/fotoshooting/treppe.jpg",
  },
  {
    title: "",
    type: "shooting",
    src: "images/gallery/fotoshooting/white_gatter.jpg",
  },
  {
    title: "",
    type: "shooting",
    src: "images/gallery/fotoshooting/corona.jpg",
  },
  {
    title: "",
    type: "wedding",
    src: "",
  },
  {
    title: "",
    type: "party",
    src: "",
  },
  {
    title: "",
    type: "ceremony",
    src: "",
  },
  {
    title: "",
    type: "wedding",
    src: "",
  },
];
