import React from "react";
import { Wrapper } from "./components/Wrapper";
import Hero from "./components/Hero";
import WeddingCoupleSection from "./components/WeddingCoupleSection";
import CountDown from "./components/CountDown";
import StorySection from "./components/StorySection";
import CTA from "./components/CTA";
import EventSection from "./components/EventSection";
import ImportantPeople from "./components/ImportantPeople";
import Gallery from "./components/Gallery";
import RVSP from "./components/RVSP";
import Info from "./components/Info";
import { isMobileSafari, isIOS } from "react-device-detect";

function App() {
  if (isMobileSafari || isIOS) {
    return (
      <div style={{margin: '0 auto', width:'60%'}}>
      <h1>Liebe Apple-Fanatiker <span>😉</span>,</h1>        
      <p>leider funktioniert unsere Seite nicht auf
        Euren Geräten.<br></br> 
        <h2>Unser Vorschlag:</h2> 
        <ul>
          <li>Schmeißt weg “das Klump“ und kauft Euch
        ein “gscheites“ Gerät <span>😉</span> </li>
          <li>ODER</li>
          <li> Versucht es auf Eurem Notebook, PC oder
        einem anderen Gerät mit Android-Betriebssystem.</li>
        </ul>     <h3>Es tut uns leid und wir
        hoffen, ihr kommt uns trotzdem auf unserer Webseite besuchen!</h3> </p>
        
        <h2>Simone & Sebastian</h2>
        </div>
      
    );
  } else {
    return (
      <div id="home">
        <Wrapper>
          <Hero />
          <Info />
          <WeddingCoupleSection />
          <CountDown />
          <StorySection />
          <CTA />
          {/* <EventSection /> */}
          <ImportantPeople />
          <Gallery />
          <RVSP />
        </Wrapper>
      </div>
    );
  }
}

export default App;
