import React from "react";

export default function CTA() {
  return (
    <section
      className="cta section-padding parallax"
      data-bg-image="images/cta-bg.jpg"
      data-speed="7"
    >
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <h2>
              <span>Feiert zusammen mit uns ...</span> Unseren Großen Tag
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
